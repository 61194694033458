import styled from "styled-components";

export const Wrapper = styled.div`
    margin: ${({ as }) => (as === "p" ? "0 0 10px" : "0")};

    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    p,
    ul {
        margin: 0 0 10px;
    }
`;
