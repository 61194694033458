import PropTypes from "prop-types";
import styled from "styled-components";

import { skeleton } from "components/ui/skeleton/Skeleton";

const Container = styled.div`
    width: ${({ width }) => width}px;
    padding: 0;

    border-radius: calc(${({ width }) => width}px / 15);
    aspect-ratio: var(--aspect-ratio-card);

    ${skeleton}
`;

function SkeletonCard({ width }) {
    return <Container width={width} />;
}
SkeletonCard.propTypes = {
    width: PropTypes.number
};

SkeletonCard.defaultProps = {
    width: 482
};

export default SkeletonCard;
