import PropTypes from "prop-types";

function IconChevronLeft({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            fill={fill}
            {...rest}
        >
            <path d="M23.67 27.24 38.52 12.39 42.76 16.63 27.91 31.48 42.76 46.33 38.52 50.58 19.43 31.48 23.67 27.24z" />
        </svg>
    );
}

IconChevronLeft.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconChevronLeft.defaultProps = {
    fill: "var(--color-black)",
    size: 48,
    style: { verticalAlign: "middle" }
};

export default IconChevronLeft;
