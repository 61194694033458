import styled from "styled-components";
import Image from "next/image";

import media from "constants/media";

export const Card = styled.div`
    position: relative;

    width: 100%;
    max-width: ${({ width }) => width}px;
    overflow: hidden;
    aspect-ratio: var(--aspect-ratio-card);

    border-radius: calc(${({ width }) => width}px / 15);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

    ${media.lgUp} {
        width: ${({ width }) => width}px;
    }
`;

export const CardImage = styled(Image)`
    border-radius: calc(${({ width }) => width}px / 15);
    aspect-ratio: var(--aspect-ratio-card);
`;
