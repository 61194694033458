import { keyframes, css } from "styled-components";

const shine = keyframes`
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-65%);
    }
`;

/* Use ::before with translateX to avoid non-composited animations
 * See: https://web.dev/non-composited-animations/
 */
export const skeleton = css`
    --color-skeleton-screen-bg: var(--color-primary);
    --color-skeleton-screen-shine: var(--color-white);

    position: relative;

    overflow: hidden;

    ::before {
        position: absolute;
        top: 0;
        left: 0;

        width: 400%;
        height: 100%;

        background-color: var(--color-skeleton-screen-bg);
        background-image: linear-gradient(
            to right,
            var(--color-skeleton-screen-bg),
            var(--color-skeleton-screen-bg),
            var(--color-skeleton-screen-bg),
            var(--color-skeleton-screen-shine),
            var(--color-skeleton-screen-bg),
            var(--color-skeleton-screen-bg),
            var(--color-skeleton-screen-bg)
        );
        background-size: 100%;

        animation: ${shine} 2s var(--animation-easing-standard) infinite;

        content: "";
    }
`;
