import styled, { css } from "styled-components";

import { hexToRgba } from "helpers/utils";
import media from "constants/media";
import colors from "constants/colors";

const Button = css`
    .slider-nav-btn {
        padding: 13px;

        color: var(--color-primary);

        background: ${hexToRgba(colors.gray80, 0.4)};
        border: 2px solid ${hexToRgba(colors.gray70, 0.4)};

        border-radius: 100%;
        cursor: pointer;

        transition: all var(--animation-duration-default) ease-in-out;

        &:disabled {
            cursor: not-allowed;
            opacity: 0.2;
        }

        :not(:disabled):hover,
        :not(:disabled):focus {
            color: var(--color-white);

            background-color: var(--color-purple);
            border-color: var(--color-purple);
        }
    }
`;

const Pagination = css`
    .slider-dots-dot-custom {
        padding: 0 6px;
    }

    .paging-dot {
        width: 10px;
        height: 10px;

        transition: all var(--animation-duration-default) ease;

        fill: var(--color-primary);

        circle {
            cx: 5;
            cy: 5;
            r: 5px;
        }
    }

    .paging-item.active .paging-dot {
        transform: scale(1.4);
    }
`;

const Slider = css`
    .slider,
    .slider-slide {
        outline: none;
    }

    .slider {
        padding-bottom: 80px;
    }
`;

export const Container = styled.div`
    width: 100%;

    ${({ fadeEdges }) =>
        fadeEdges &&
        css`
            ${media.mdUp} {
                width: calc(100% + 300px);
                padding: 20px 200px 0 100px;
                margin: -20px -200px 0 -100px;
                overflow: hidden;

                .slider-control-topleft,
                .slider-control-topright {
                    width: 0;
                    height: 100%;
                }
            }
        `}

    ${Slider}

    ${Pagination}

    ${Button}
`;

const Fade = css`
    position: absolute;
    top: -20px;

    height: calc(100% + 80px);
`;

export const LeftFade = styled.div`
    left: -100px;

    width: 100px;

    background: linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 20%
    );

    ${Fade}
`;

export const RightFade = styled.div`
    right: -250px;

    width: 300px;

    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 75%
    );

    ${Fade}
`;
