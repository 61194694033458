import PropTypes from "prop-types";
import Carousel, {
    NextButton,
    PagingDots,
    PreviousButton
} from "nuka-carousel";

import IconChevronLeft from "components/ui/icons/IconChevronLeft";
import IconChevronRight from "components/ui/icons/IconChevronRight";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import { Container, LeftFade, RightFade } from "./Slider.styled";

function shouldShowControls(navigationMobile, navigationDesktop, mobileView) {
    return !!(
        (navigationMobile && mobileView) ||
        (navigationDesktop && !mobileView)
    );
}

const buttonStyles = {
    padding: "unset-inline-style",
    background: "unset-inline-style",
    border: "unset-inline-style",
    opacity: "unset-inline-style",
    color: "unset-inline-style",
    textTransform: "unset-inline-style"
};

function Slider({
    className,
    items,
    slidesToShow,
    navigationMobile,
    navigationDesktop,
    overflowVisible,
    infinite,
    fadeEdges
}) {
    const { mobileView } = useMediaQueryContext();
    const showControls = shouldShowControls(
        navigationMobile,
        navigationDesktop,
        mobileView
    );

    return (
        <Container
            navigationMobile={navigationMobile}
            navigationDesktop={navigationDesktop}
            fadeEdges={fadeEdges}
            className={className}
        >
            <Carousel
                cellSpacing={20}
                cellAlign="left"
                defaultControlsConfig={{
                    pagingDotsContainerClassName:
                        "slider-dots-container-custom",
                    pagingDotsClassName: "slider-dots-dot-custom",
                    // Set non-existing value to remove inline styles
                    prevButtonStyle: buttonStyles,
                    prevButtonClassName: "slider-nav-btn",
                    prevButtonText: (
                        <IconChevronLeft
                            size={24}
                            fill="currentColor"
                            aria-label="Vorige item"
                        />
                    ),
                    // Set non-existing value to remove inline styles
                    nextButtonStyle: buttonStyles,
                    nextButtonClassName: "slider-nav-btn",
                    nextButtonText: (
                        <IconChevronRight
                            size={24}
                            fill="currentColor"
                            aria-label="Volgende item"
                        />
                    )
                }}
                autoGenerateStyleTag={false}
                edgeEasing="easeExpOut"
                heightMode="max"
                slidesToShow={
                    items?.length >= slidesToShow ? slidesToShow : items?.length
                }
                slidesToScroll="auto"
                wrapAround={infinite}
                width="100%"
                vertical={false}
                frameOverflow={overflowVisible ? "visible" : "hidden"}
                getControlsContainerStyles={key => {
                    switch (key) {
                        case "BottomCenter":
                            return {
                                bottom: "15px"
                            };
                        default:
                            return "";
                    }
                }}
                renderCenterLeftControls={null}
                renderBottomCenterControls={props => {
                    if (slidesToShow >= items.length) {
                        return null;
                    }

                    return <PagingDots {...props} />;
                }}
                renderBottomLeftControls={props =>
                    showControls && <PreviousButton {...props} />
                }
                renderCenterRightControls={null}
                renderBottomRightControls={props =>
                    showControls && <NextButton {...props} />
                }
                renderTopLeftControls={() =>
                    fadeEdges && !mobileView && <LeftFade />
                }
                renderTopRightControls={() =>
                    fadeEdges && !mobileView && <RightFade />
                }
            >
                {items}
            </Carousel>
        </Container>
    );
}

Slider.propTypes = {
    className: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    slidesToShow: PropTypes.number,
    navigationMobile: PropTypes.bool,
    navigationDesktop: PropTypes.bool,
    overflowVisible: PropTypes.bool,
    infinite: PropTypes.bool,
    fadeEdges: PropTypes.bool
};

Slider.defaultProps = {
    className: "",
    items: [],
    slidesToShow: 1.2,
    navigationMobile: true,
    navigationDesktop: true,
    overflowVisible: false,
    infinite: false,
    fadeEdges: false
};

export default Slider;
