import PropTypes from "prop-types";
import parse from "html-react-parser";

import { containsHTML } from "helpers/utils";

import { Wrapper } from "./ContentRenderer.styled";

function ContentRenderer({ children, className, defaultElement }) {
    const element = containsHTML(children) ? "div" : defaultElement;

    if (!children) {
        return null;
    }

    return (
        <Wrapper as={element} className={className}>
            {parse(children)}
        </Wrapper>
    );
}

ContentRenderer.propTypes = {
    children: PropTypes.string.isRequired,
    defaultElement: PropTypes.string,
    className: PropTypes.string
};

ContentRenderer.defaultProps = {
    defaultElement: "p",
    className: ""
};

export default ContentRenderer;
